import { type ProjectViewMode, projectViewModes, projectsSortOptions } from '@training/constants'
import { create } from 'zustand'
import { devtools, persist, createJSONStorage } from 'zustand/middleware'
import { updateProgress } from '@training/utils/updateProgress'
import { Deployment } from '@htaic/anaheim-graphql-library'

interface VideoClipMetadata {
  videoFileId?: string
  status?: string
}

export interface TrainingProgressMetadata {
  projectId?: string
  totalSteps?: number
  completedSteps?: number
  trainingProgress?: number
}

interface AppStateStore {
  confidenceLevelValue: number | null
  setConfidenceLevelValue: (confidenceLevelValue: number | null) => void
  isStartingTraining: boolean
  setIsStartingTraining: (isStartingTraining: boolean) => void
  comparePlayerEnabled: boolean
  setComparePlayerEnabled: (comparePlayerEnabled: boolean) => void
  projectsSearchQuery: string
  setProjectsSearchQuery: (projectsSearchQuery: string) => void
  projectsSortingOption: (typeof projectsSortOptions)[number]['id']
  setProjectsSortingOption: (
    projectsSortingOption: (typeof projectsSortOptions)[number]['id']
  ) => void
  projectViewMode: ProjectViewMode
  setProjectViewMode: (projectViewMode: ProjectViewMode) => void
  suggestedFramesClipsProgress: number
  setsuggestedFramesClipsProgress: (suggestedFramesClipsProgress: number) => void
  videoClipMetadata: VideoClipMetadata
  setVideoClipMetadata: (videoClipMetadata: VideoClipMetadata) => void
  setIsHeaderBannerOpen: (isHeaderBannerOpen: boolean) => void
  isHeaderBannerOpen: boolean
  setTrainingProgresses: (trainingProgressMetadata: TrainingProgressMetadata) => void
  trainingProgresses: TrainingProgressMetadata[]
  setDeploymentStatus: (deploymentStatuses: Deployment) => void
  clearDeploymentStatuses: () => void
  deploymentStatuses: Deployment[]
}

export const useAppState = create<AppStateStore>()(
  persist(
    devtools((set) => ({
      isStartingTraining: false,
      setIsStartingTraining: (isStartingTraining) => set({ isStartingTraining }),
      setComparePlayerEnabled: (comparePlayerEnabled) => set({ comparePlayerEnabled }),
      comparePlayerEnabled: false,
      setProjectsSortingOption: (projectsSortingOption) => set({ projectsSortingOption }),
      projectsSortingOption: '1',
      projectsSearchQuery: '',
      setProjectsSearchQuery: (projectsSearchQuery) => set({ projectsSearchQuery }),
      confidenceLevelValue: null,
      setConfidenceLevelValue: (confidenceLevelValue) => set({ confidenceLevelValue }),
      projectViewMode: projectViewModes.INITIAL,
      setProjectViewMode: (projectViewMode) => set({ projectViewMode }),
      suggestedFramesClipsProgress: 0,
      setsuggestedFramesClipsProgress: (suggestedFramesClipsProgress) =>
        set({ suggestedFramesClipsProgress }),
      videoClipMetadata: {},
      setVideoClipMetadata: (videoClipMetadata) => set({ videoClipMetadata }),
      setIsHeaderBannerOpen: (isHeaderBannerOpen) => set({ isHeaderBannerOpen }),
      isHeaderBannerOpen: false,
      trainingProgresses: [],
      setTrainingProgresses: (trainingProgressMetadata: TrainingProgressMetadata) => {
        set((state) => {
          const updatedTrainingProgresses = updateProgress(
            state.trainingProgresses,
            trainingProgressMetadata,
            'training'
          )
          return { trainingProgresses: updatedTrainingProgresses }
        })
      },
      setDeploymentStatus: (deploymentStatus: Deployment) => {
        set((state) => {
          const existingDeploymentStatus = state.deploymentStatuses.find(
            (deploy) => deploymentStatus.deploymentId === deploy.deploymentId
          )
          if (existingDeploymentStatus) {
            return {
              deploymentStatuses: state.deploymentStatuses?.map((deploy) => {
                if (deploymentStatus.deploymentId === deploy.deploymentId) {
                  return deploymentStatus
                }
                return deploy
              }),
            }
          }
          return {
            deploymentStatuses: [...state.deploymentStatuses, deploymentStatus],
          }
        })
      },
      clearDeploymentStatuses: () => set({ deploymentStatuses: [] }),
      deploymentStatuses: [],
    })),
    {
      name: 'app-state',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        projectsSortingOption: state.projectsSortingOption,
      }),
    }
  )
)
