import { Button, Typography, ModalContent, ModalFooter, ModalHeader } from '@htaic/cue'
import { useEffect, useState } from 'react'
import DarkMode from '@training/assets/dark_mode.png'
import LightMode from '@training/assets/light_mode.png'
import SyncWithBrowserMode from '@training/assets/browser_mode.png'
import { Theme, themes, useMinimeState } from '@training/store/useMinimeState'
import {
  update_user_user_settings_by_pk,
  flushUserCache,
  UserSettingsThemeEnum,
} from '@htaic/anaheim-graphql-library'
import { AppThemeCard } from './AppThemeCard'

interface PersonalSettingsModalProps {
  onClose: () => void
}

export const PersonalSettingsModalApp = ({ onClose }: PersonalSettingsModalProps) => {
  const [selectedTheme, setSelectedTheme] = useState<Theme>(themes.LIGHT)

  const theme = useMinimeState((state) => state.theme)
  const setTheme = useMinimeState((state) => state.setTheme)

  const userId = useMinimeState((state) => state.userId)

  const handleModeClick = (newTheme: Theme) => {
    setSelectedTheme(newTheme)
  }

  const handleClick = async () => {
    try {
      await update_user_user_settings_by_pk(
        { theme: selectedTheme as UserSettingsThemeEnum },
        { id: userId }
      )
      await flushUserCache()
      setTheme(selectedTheme)
      onClose()
    } catch (error) {
      console.error('Error in updating theme', error)
    }
  }

  useEffect(() => {
    setSelectedTheme(theme)
  }, [theme])

  return (
    <ModalContent data-testid='personal-settings-modal'>
      <ModalHeader closable>Preferences</ModalHeader>
      <div className='grid grid-cols-[9.5rem,1fr] gap-x-14 pt-5 '>
        <Typography>Color theme</Typography>
        <div className='flex justify-between gap-x-3 mb-6'>
          <AppThemeCard
            selected={selectedTheme === themes.DARK}
            themeSrc={DarkMode}
            themeName='Dark'
            themeMode={themes.DARK}
            handleClick={() => handleModeClick(themes.DARK)}
          />
          <AppThemeCard
            selected={selectedTheme === themes.LIGHT}
            themeSrc={LightMode}
            themeName='Light'
            themeMode={themes.LIGHT}
            handleClick={() => handleModeClick(themes.LIGHT)}
          />
          <AppThemeCard
            selected={selectedTheme === themes.SYSTEM}
            themeSrc={SyncWithBrowserMode}
            themeName='Sync with OS'
            themeMode={themes.SYSTEM}
            handleClick={() => handleModeClick(themes.SYSTEM)}
          />
        </div>
      </div>
      <ModalFooter className='justify-end'>
        <Button className='w-24' onClick={handleClick}>
          OK
        </Button>
        <Button className='w-24 text-white' color='grey' onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
