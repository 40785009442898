import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { LicenseOrgSettings } from '@htaic/anaheim-graphql-library'
import { compact, inRange } from 'lodash'
import { useGetAvailableLicenses } from '@training/apis/licenses/requests'
import { useGetActiveLicense } from '@training/hooks/useGetActiveLicense'

export interface LicensePlanStore {
  id: string
  orgId: string
  currentStorage: number
  currentProcesses: number
  currentProjects: number
  maxStorage: number
  maxProcesses: number
  maxProjects: number
  setLicensePlan: (data: LicenseOrgSettings) => void
  licensesStorageExceeded: boolean
  licenseProcessesExceeded: boolean
  licenseProjectsExceeded: boolean
}

export const useLicensePlanState = create<LicensePlanStore>()(
  persist(
    (set) => ({
      id: '',
      orgId: '',
      currentProcesses: 0,
      currentProjects: 0,
      currentStorage: 0,
      maxStorage: 0,
      maxProcesses: 0,
      maxProjects: 0,
      setLicensePlan: async (data: LicenseOrgSettings) => {
        set({
          id: data.id,
          orgId: data.orgid,
          currentStorage: data.current_storage,
          currentProcesses: data.current_processes,
          currentProjects: data.current_projects,
          maxProcesses: data.max_processes,
          maxProjects: data.max_projects,
          maxStorage: data.max_storage,
          licenseProcessesExceeded: data.current_processes >= data.max_processes,
          licenseProjectsExceeded: data.current_projects >= data.max_projects,
          licensesStorageExceeded: data.current_storage >= data.max_storage,
        })
      },
      licensesStorageExceeded: false,
      licenseProcessesExceeded: false,
      licenseProjectsExceeded: false,
    }),
    {
      name: 'LicensePlan-state',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

const REMAINING_PROCESSES_WARNING_AMOUNT = 10
const REMAINING_PROJECTS_WARNING_AMOUNT = 2
const REMAINING_STORAGE_WARNING_AMOUNT = 0.1

const REMAINING_LICENSE_DAYS_WARNING_AMOUNT = 30

export const useLicenseWarningMessage = () => {
  const availableLicenses = useGetAvailableLicenses()

  const isProcessesNearLimit = useLicensePlanState((state) =>
    inRange(state.maxProcesses - state.currentProcesses, 1, REMAINING_PROCESSES_WARNING_AMOUNT + 1)
  )
  const isProjectsNearLimit = useLicensePlanState((state) =>
    inRange(state.maxProjects - state.currentProjects, 1, REMAINING_PROJECTS_WARNING_AMOUNT + 1)
  )
  const isStorageNearLimit = useLicensePlanState(
    (state) => 1 - state.currentStorage / state.maxStorage <= REMAINING_STORAGE_WARNING_AMOUNT
  )

  const activeLicense = useGetActiveLicense(availableLicenses.data ?? [])

  const isAboutToExpire = inRange(
    activeLicense.daysLeft,
    1,
    REMAINING_LICENSE_DAYS_WARNING_AMOUNT + 1
  )

  if (!isProcessesNearLimit && !isProjectsNearLimit && !isStorageNearLimit && !isAboutToExpire) {
    return {
      message: null,
    }
  }

  const limitNames = compact([
    isProcessesNearLimit && 'trainings',
    isProjectsNearLimit && 'projects',
    isStorageNearLimit && 'storage',
  ])

  const formatter = new Intl.ListFormat('en')

  const hasLimitWarnings = limitNames.length > 0

  return {
    message: ''.concat(
      isAboutToExpire ? 'Your FLEX AI license is about to expire. ' : '',
      hasLimitWarnings
        ? `You are approaching your limit for ${formatter.format(limitNames)}. `
        : '',
      isAboutToExpire || hasLimitWarnings
        ? 'Go to your FLEX AI licenses or contact your System Integrator (SI) to add more.'
        : ''
    ),
  }
}
