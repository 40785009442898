import { round } from 'lodash'

export interface ProgressMetadata {
  totalSteps?: number
  completedSteps?: number
}

export interface ProgressConfig {
  idKey: string
  progressKey: string
}

export const progressConfig: Record<'training', ProgressConfig> = {
  training: {
    idKey: 'projectId',
    progressKey: 'trainingProgress',
  },
}

/**
 * Calculate the progress percentage given the total steps and completed steps.
 *
 * @param {Object} [metadata] - metadata object with totalSteps and completedSteps properties.
 * @returns {number} progress percentage.
 */
export const calculateProgress = ({ totalSteps, completedSteps }: ProgressMetadata): number =>
  totalSteps && completedSteps !== undefined ? round((100 * completedSteps) / totalSteps) : 0

/**
 * Updates the progress state with the given metadata.
 *
 * @param {T[]} progressState - progress state array.
 * @param {T} updateMetadata - metadata to update the progress state with.
 * @param {'training'} progressType - type of progress to update.
 * @returns {T[]} updated progress state array.
 *
 * If the id specified in the metadata exists in the progress state, it is updated.
 * Otherwise, the new metadata is appended to the progress state.
 */
export const updateProgress = <T extends ProgressMetadata>(
  progressState: T[],
  updateMetadata: T,
  progressType: 'training'
): T[] => {
  const { idKey, progressKey } = progressConfig[progressType]
  const newProgress = {
    ...updateMetadata,
    [progressKey]: calculateProgress(updateMetadata),
  }

  const progressIndex = progressState.findIndex(
    (p) => p[idKey as keyof T] === updateMetadata[idKey as keyof T]
  )

  return progressIndex !== -1
    ? progressState.map((p, index) => (index === progressIndex ? newProgress : p))
    : [...progressState, newProgress]
}
