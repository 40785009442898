import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import OpenStandardLogging from './openStandardLogging'
import ErrorBoundary from './providers/ErrorBoundary'
import { router } from './routes/routes'
import './index.css'

declare global {
  interface Window {
    PROJECT_VERSION: string
  }
}

window.PROJECT_VERSION = PROJECT_VERSION

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <OpenStandardLogging />
    <ErrorBoundary fallback={null}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>
)
