import { endpoints } from '@training/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useMinimeState } from '@training/store/useMinimeState'
import { AvailableLicensesResponse, ClaimLicense, LicensesStatusResponse } from '../types'

export const licensesApiUrl = endpoints.VITE_LICENSES_API ?? 'http://localhost:3029'

const headers = {
  'Content-Type': 'application/json',
}

export const getAvailableLicenses = async (orgId: string) => {
  const axiosCreate = axios.create({
    headers: {
      ...headers,
      'X-Flexai-Orgid': orgId,
    },
    withCredentials: true,
  })

  const { data } = await axiosCreate<AvailableLicensesResponse[]>(
    `${licensesApiUrl}/license/${orgId}/available-licenses/`
  )
  return data
}

export function useGetAvailableLicenses() {
  const orgId = useMinimeState((state) => state.orgId)

  return useQuery({
    queryKey: ['availableLicenses', orgId],
    queryFn: () => getAvailableLicenses(orgId),
    staleTime: 1000 * 60 * 1,
    gcTime: 1000 * 60 * 5,
    enabled: !!orgId,
  })
}

export function useUpdateToClaimLicense() {
  const orgId = useMinimeState((state) => state.orgId)

  const updateData = async (payload: ClaimLicense) => {
    const { data } = await axios.put(`${licensesApiUrl}/license/${orgId}`, payload, {
      headers: {
        ...headers,
        'X-Flexai-Orgid': orgId,
      },
      withCredentials: true,
    })
    return data
  }
  return useMutation({
    mutationKey: ['updateProject'],
    mutationFn: (payload: ClaimLicense) => updateData(payload),
  })
}

export const getLicenseStatus = async (orgId: string | null) => {
  const axiosCreate = axios.create({
    headers: {
      ...headers,
      'X-Flexai-Orgid': orgId,
    },
    withCredentials: true,
  })
  const { data } = await axiosCreate.get<LicensesStatusResponse>(
    `${licensesApiUrl}/license/${orgId}/status`
  )
  return data
}

export function useGetLicenseStatus(
  orgId: string | null,

  { enabled }: { enabled: boolean } = { enabled: true }
) {
  return useQuery({
    queryKey: ['licenseStatus', orgId],
    queryFn: () => getLicenseStatus(orgId),
    enabled: !!orgId && enabled,
    retry: 1,
  })
}
