import { cloudPlatformLoginUrls, urlParams } from '@training/constants'

/**
 * This function is used to determine the redirect url for the cloud platform login
 * based on the current flexai url
 * @returns {string} - the redirect url for the cloud platform login
 * by default it will return the first url in the cloudPlatformLoginUrls array
 */
export const getRedirectUrl = () => {
  const currentCloudPlatformLoginUrl = cloudPlatformLoginUrls.find(
    (url) => url.flexaiUrl === window.location.hostname
  )
  if (currentCloudPlatformLoginUrl) {
    return currentCloudPlatformLoginUrl.cloudUrl
  }
  return cloudPlatformLoginUrls[0].cloudUrl
}

/**
 * Formats the redirect url by adding the orgId query parameter if provided
 * @param {string} url - the url to format
 * @param {string} [orgId] - the orgId to add to the query parameter
 * @returns {string} - the formatted url
 */
export const formatRedirectUrl = (url: string, orgId?: string) => {
  const redirectUrl = new URL(window.location.href)

  if (orgId) {
    redirectUrl.searchParams.set(urlParams.ORG_ID, orgId)
    url.concat(`?redirect_app_url=${redirectUrl.toString()}`)
  }

  return url
}

/**
 * Determines the environment name based on the current FlexAI URL.
 * @returns {string} - The environment name corresponding to the current FlexAI URL.
 * Defaults to the name of the first entry in the cloudPlatformLoginUrls array if no match is found.
 */
export const getEnvironmentName = () => {
  const currentFlexAiUrl = window.location.hostname
  const currentCloudPlatformLoginUrl = cloudPlatformLoginUrls.find(
    (url) => url.flexaiUrl === currentFlexAiUrl
  )
  if (currentCloudPlatformLoginUrl) {
    return currentCloudPlatformLoginUrl.name
  }
  return cloudPlatformLoginUrls[0].name
}
