import { Outlet, NavLink } from 'react-router-dom'
import {
  AppsCard,
  ProfileCard,
  Button,
  Loader,
  Icon,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Sidebar,
  SidebarBranding,
  SidebarContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  Typography,
} from '@htaic/cue'
import React, { Suspense, useCallback, useEffect } from 'react'
import { themes, useMinimeState } from '@training/store/useMinimeState'
import { useGetAppLinks } from '@training/hooks/useGetAppLinks'
import { logoutUser } from '@training/auth/logoutUser'
import { useModalState } from '@training/hooks/useModal'
import useDarkMode from '@training/hooks/useDarkMode'
import { useFeatureFlags } from '@training/store/useFeatureFlags'
import { endpoints, envVars, flexAiSupportUrl, storageKeys } from '@training/constants'
import { storage } from '@training/utils/storage'
import { formatRedirectUrl, getRedirectUrl } from '@training/utils/getRedirectUrl'
import { getMiniMe, setAppConfig } from '@htaic/anaheim-graphql-library'
import { FeatureFlagsModal } from '@training/components/Modals/FeatureFlagsModal'
import { PersonalSettingsModalApp } from '@training/components/Modals/PersonalSettingsModal/PersonalSettingsModal'
import { HeaderBanner } from '@training/components/HeaderBanner'
import { useLicensePlanState, useLicenseWarningMessage } from '@training/store/useLicenseStatus'
import { getLicensePlanMessage } from '@training/utils/getLicensePlanMessage'
import { SidebarItem } from '@training/types'
import HelpCard, { HelpLinkProps } from '@training/components/HelpCard'
import { useGetAvailableLicenses } from '@training/apis/licenses/requests'
import { cloudPlatformAccountSettingsUrls } from '@training/constants'
import { getEnvironmentName } from '@training/utils/getRedirectUrl'
import { validateExpiredLicense } from '@training/utils/validateExpiredLicense'

const redirectOut = (orgId?: string) => {
  if (envVars.DEV_DISABLE_REDIRECTS) {
    return
  }

  const cloudPlatformLoginUrl = getRedirectUrl()
  window.location.href = formatRedirectUrl(cloudPlatformLoginUrl, orgId)
}

const envName = getEnvironmentName()

const AppLoader = () => (
  <div
    className='flex items-center justify-center h-full grow'
    aria-live='polite'
    aria-label='Loading projects'
    data-testid='loading-projects'
  >
    <Loader
      size='xxxlarge'
      iconName='LoadingThin'
      className='text-primary dark:text-neutral-grey-0'
    />
  </div>
)

const LicenseLimitsWarning = () => {
  const licenseWarningMessage = useLicenseWarningMessage()

  if (licenseWarningMessage.message) {
    return (
      <HeaderBanner open type='warning'>
        {licenseWarningMessage.message}
      </HeaderBanner>
    )
  }

  return null
}

const LicenseLimitsAlert = () => {
  const { licenseProjectsExceeded, licenseProcessesExceeded, licensesStorageExceeded } =
    useLicensePlanState()

  const availableLicenses = useGetAvailableLicenses()

  // Don't change the order of the keys
  const limitsReachedMessage = getLicensePlanMessage({
    isExpired: validateExpiredLicense(availableLicenses.data),
    processesExceeded: licenseProcessesExceeded,
    projectsExceeded: licenseProjectsExceeded,
    storageExceeded: licensesStorageExceeded,
  })

  if (limitsReachedMessage) {
    return (
      <HeaderBanner open type='error' className='bg-hv-status-error'>
        {limitsReachedMessage}
      </HeaderBanner>
    )
  }

  return <LicenseLimitsWarning />
}

const menuItems = [
  {
    title: 'Projects',
    url: '/training',
    icon: 'ThumbnailView',
  },
  {
    title: 'Devices',
    url: '/training/devices',
    icon: 'DomeCamera',
  },
  {
    title: 'Settings',
    url: '/training/settings',
    icon: 'Settings',
  },
] satisfies SidebarItem[]

const helpLinks = [
  {
    label: 'Training',
    url: flexAiSupportUrl,
    iconLeft: (
      <Icon name='Trencher' className='text-hv-neutral-10 dark:text-hv-neutral-3 self-start mr-2' />
    ),
    iconRight: (
      <Icon
        name='Next'
        className='-rotate-45 text-hv-neutral-10 dark:text-hv-neutral-3 self-center stroke-2'
      />
    ),
  },
] satisfies HelpLinkProps[]

export const MainLayout = () => {
  const orgId = useMinimeState((state) => state.orgId)

  const setUser = useMinimeState((state) => state.setUser)
  const setTheme = useMinimeState((state) => state.setTheme)
  const setOrgName = useMinimeState((state) => state.setOrgName)

  useEffect(() => {
    if (!orgId) {
      redirectOut()
      return
    }

    async function callMinime() {
      try {
        setAppConfig(endpoints.GRAPHQLURL, undefined, true, orgId)

        const minimeResponse = await getMiniMe()
        if (!minimeResponse) {
          throw new Error('Minime response is empty')
        }

        storage().setItem(storageKeys.USER_ID, minimeResponse?.accountId ?? '')

        sessionStorage.setItem(
          storageKeys.SESSION_TIMEOUT_MINUTES,
          `${minimeResponse?.sessionTimeout}`
        )

        setTheme(minimeResponse?.userSettings?.theme ?? 'light')
        setUser({
          userName: minimeResponse?.username ?? '',
          firstName: minimeResponse?.firstName ?? '',
          lastName: minimeResponse?.lastName ?? '',
          userId: minimeResponse?.accountId ?? '',
        })
        setOrgName(minimeResponse?.orgName ?? '')
      } catch (error) {
        console.error('Error in fetching minime', error)
        redirectOut(orgId)
      }
    }
    callMinime()
  }, [orgId, setOrgName, setTheme, setUser])

  const appsLinks = useGetAppLinks()
  const { firstName, lastName, userName, theme } = useMinimeState()

  const devFFManagement = useFeatureFlags().DEV_FF_MANAGEMENT

  const openModal = useModalState((state) => state.openModal)
  const closeModal = useModalState((state) => state.closeModal)

  const onPreferencesClick = useCallback(() => {
    openModal({
      size: 'lg',
      content: <PersonalSettingsModalApp onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const handleAccountSettingsClick = () => {
    const matchingEntry = cloudPlatformAccountSettingsUrls.find(({ name }) =>
      name.some((env) => env === envName)
    )

    const url = matchingEntry?.url || cloudPlatformAccountSettingsUrls[0].url

    window.location.href = url
  }

  const handleLogOut = useCallback(async () => {
    closeModal()
    await logoutUser(false, true)
  }, [closeModal])

  const onLogoutModalOpen = useCallback(() => {
    openModal({
      size: 'sm',
      content: (
        <ModalContent>
          <ModalHeader closable>Logout</ModalHeader>
          <div className='flex py-4'>
            <Typography variant='body2' className='text-center'>
              Are you sure you want to log out of FLEX AI and the Hanwha Cloud Platform?
            </Typography>
          </div>
          <ModalFooter className='flex justify-end'>
            <Button onClick={handleLogOut}>Logout</Button>
            <Button variant='secondary' onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      ),
    })
  }, [closeModal, handleLogOut, openModal])

  const onFeatureFlagsModalOpen = useCallback(() => {
    openModal({
      size: 'lg',
      content: <FeatureFlagsModal onClose={closeModal} />,
    })
  }, [closeModal, openModal])

  const { darkMode } = useDarkMode(theme)

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? themes.DARK : '')
    if (darkMode) {
      document.body.classList.add(themes.DARK)
    } else {
      document.body.classList.remove(themes.DARK)
    }
  }, [darkMode])

  return (
    <div
      className='w-full bg-neutral-grey-16 text-neutral-grey-0 flex relative min-h-svh'
      data-testid='container-app'
    >
      <Sidebar
        collapsible='icon'
        variant='floating'
        side='left'
        className='z-50 fixed [&>div]:transition-[width] [&>div]:w-16'
      >
        <SidebarContent className='bg-white dark:bg-hv-neutral-18'>
          <SidebarBranding />
          <SidebarMenu>
            {menuItems.map((item) => (
              <SidebarMenuItem key={item.title}>
                <NavLink to={item.url} className='no-underline' end>
                  {({ isActive }) => (
                    <SidebarMenuButton isActive={isActive} icon={item.icon} title={item.title} />
                  )}
                </NavLink>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarContent>
      </Sidebar>
      <div className='flex flex-col grow pl-16'>
        <header className='flex justify-between items-center text-center px-5 py-2 h-12'>
          <div className='w-1/3 text-left flex items-center'>
            <Typography variant='caption' className='text-[24px] font-semibold'>
              FLEX AI
            </Typography>
          </div>
          <div className='flex flex-row w-1/3 justify-end items-center'>
            <HelpCard>
              <HelpCard.Trigger />
              <HelpCard.Popper containerClassName='z-20' helpLinks={helpLinks} />
            </HelpCard>
            <AppsCard>
              <AppsCard.Trigger />
              <AppsCard.Popper containerClassName='z-20' appsLinks={appsLinks} />
            </AppsCard>
            <ProfileCard>
              <ProfileCard.Trigger firstName={firstName} lastName={lastName} />
              <ProfileCard.Content
                containerClassName='z-20'
                firstName={firstName}
                lastName={lastName}
                userName={userName}
              >
                <ProfileCard.Action
                  label='Account settings'
                  iconLeft={<Icon name='AdminCircle' size='small' />}
                  iconRight={<Icon name='ChevronRight' size='small' className='p-1' />}
                  onClick={handleAccountSettingsClick}
                />
                <ProfileCard.Action
                  label='Preferences'
                  iconLeft={<Icon name='Settings' size='small' />}
                  iconRight={<Icon name='ChevronRight' size='small' className='p-1' />}
                  onClick={onPreferencesClick}
                />
                {devFFManagement ? (
                  <>
                    <ProfileCard.Separator />
                    <ProfileCard.Action
                      label='Feature Flags (Dev Only)'
                      iconLeft={<Icon name='Protocol' size='small' />}
                      onClick={onFeatureFlagsModalOpen}
                    />
                  </>
                ) : null}

                <ProfileCard.Separator />
                <ProfileCard.Action
                  label='Logout'
                  iconLeft={<Icon name='Exiting' size='small' />}
                  onClick={onLogoutModalOpen}
                />
              </ProfileCard.Content>
            </ProfileCard>
          </div>
        </header>
        {!!orgId && <LicenseLimitsAlert />}
        <Suspense fallback={<AppLoader />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}
